import React from "react";

import Head from "../components/head";
import Layout from "../components/layout";
import Section from "../components/section";
import Sitemap from "../components/sitemap";

const PageTitle = "Erreur 404 - Page introuvable";

export default () => (
  <div>
    <Head PageTitle={PageTitle} PageSlug="/404/" />

    <Layout>
      <Section Style="secondary uk-light uk-text-center">
        <h3>{PageTitle}</h3>
        <div>La page que vous demandez n'existe pas...</div>
      </Section>

      <Section Style="default">
        <Sitemap />
      </Section>
    </Layout>
  </div>
);
